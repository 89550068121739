exports.components = {
  "component---src-templates-article-resource-detail-tsx": () => import("./../../../src/templates/ArticleResourceDetail.tsx" /* webpackChunkName: "component---src-templates-article-resource-detail-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/Default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-downloadable-resource-detail-tsx": () => import("./../../../src/templates/DownloadableResourceDetail.tsx" /* webpackChunkName: "component---src-templates-downloadable-resource-detail-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/GenericPage.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-job-detail-base-tsx": () => import("./../../../src/templates/JobDetailBase.tsx" /* webpackChunkName: "component---src-templates-job-detail-base-tsx" */),
  "component---src-templates-person-detail-tsx": () => import("./../../../src/templates/PersonDetail.tsx" /* webpackChunkName: "component---src-templates-person-detail-tsx" */),
  "component---src-templates-service-detail-tsx": () => import("./../../../src/templates/ServiceDetail.tsx" /* webpackChunkName: "component---src-templates-service-detail-tsx" */),
  "component---src-templates-service-landing-tsx": () => import("./../../../src/templates/ServiceLanding.tsx" /* webpackChunkName: "component---src-templates-service-landing-tsx" */)
}

